@import "Vars";

/* oxanium-300 - latin */
@font-face {
    font-family: 'Oxanium';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/oxanium-v14-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('fonts/oxanium-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/oxanium-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/oxanium-v14-latin-300.woff') format('woff'), /* Modern Browsers */
    url('fonts/oxanium-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/oxanium-v14-latin-300.svg#Oxanium') format('svg'); /* Legacy iOS */
}
/* oxanium-regular - latin */
@font-face {
    font-family: 'Oxanium';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/oxanium-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('fonts/oxanium-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/oxanium-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/oxanium-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('fonts/oxanium-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/oxanium-v14-latin-regular.svg#Oxanium') format('svg'); /* Legacy iOS */
}
/* oxanium-600 - latin */
@font-face {
    font-family: 'Oxanium';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/oxanium-v14-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('fonts/oxanium-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/oxanium-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/oxanium-v14-latin-600.woff') format('woff'), /* Modern Browsers */
    url('fonts/oxanium-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/oxanium-v14-latin-600.svg#Oxanium') format('svg'); /* Legacy iOS */
}

@mixin font($weight) { font-family: 'Oxanium', cursive; font-weight:$weight; }
html, body, #root { height:100%; overflow: hidden; @include font(300); }
h4, strong { @include font(600); }
$barHeight: 60px;
$mainHeight: calc(100vh - 60px);
@mixin primaryGradient() {
    background: -moz-linear-gradient(180deg, #F8F18B 0, #FFE02F 100%);/* FF3.6+ */
    background: -webkit-gradient(linear, 180deg, color-stop(0, F8F18B), color-stop(100%, FFE02F));/* Chrome,Safari4+ */
    background: -webkit-linear-gradient(180deg, #F8F18B 0, #FFE02F 100%);/* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(180deg, #F8F18B 0, #FFE02F 100%);/* Opera 11.10+ */
    background: -ms-linear-gradient(180deg, #F8F18B 0, #FFE02F 100%);/* IE10+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
    background: linear-gradient(180deg, #F8F18B 0, #FFE02F 100%);/* W3C */
}
@mixin bgGradient() {
    //background: -moz-linear-gradient(270deg, #F8F8DF 50%, #F2F2DA 50%);/* FF3.6+ */
    //background: -webkit-gradient(linear, 270deg, color-stop(50%, F8F8DF), color-stop(50%, F2F2DA));/* Chrome,Safari4+ */
    //background: -webkit-linear-gradient(270deg, #F8F8DF 50%, #F2F2DA 50%);/* Chrome10+,Safari5.1+ */
    //background: -o-linear-gradient(270deg, #F8F8DF 50%, #F2F2DA 50%);/* Opera 11.10+ */
    //background: -ms-linear-gradient(270deg, #F8F8DF 50%, #F2F2DA 50%);/* IE10+ */
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
    //background: linear-gradient(270deg, #F8F8DF 50%, #F2F2DA 50%);/* W3C */

    //background: -moz-linear-gradient(270deg, #F8F8DF 70%, #F2F2DA 70%);/* FF3.6+ */
    //background: -webkit-gradient(linear, 270deg, color-stop(70%, F8F8DF), color-stop(70%, F2F2DA));/* Chrome,Safari4+ */
    //background: -webkit-linear-gradient(270deg, #F8F8DF 70%, #F2F2DA 70%);/* Chrome10+,Safari5.1+ */
    //background: -o-linear-gradient(270deg, #F8F8DF 70%, #F2F2DA 70%);/* Opera 11.10+ */
    //background: -ms-linear-gradient(270deg, #F8F8DF 70%, #F2F2DA 70%);/* IE10+ */
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
    //background: linear-gradient(270deg, #F8F8DF 70%, #F2F2DA 70%);/* W3C */
}
@mixin outerBar($bgColor, $height) {
    background: $bgColor;
    height: $height;
    @include primaryGradient;
}
button, a, .btn, .form-control {
    &:active, &:focus { outline: none !important; box-shadow: none !important; }
}
.app { height:100%; @include bgGradient;
    header {
        &.header { position: fixed; width:100%; left:0; top:0;
            @include outerBar($primary, $barHeight);
            img.brand { width:100px; height:auto; }
            .btn-back-to-website { color:$dark; text-decoration: none !important; font-weight:bold;
                i { margin-right:0.5rem; @include transition(0.2s); }
                &:hover {
                    i { margin-right:0.8rem; }
                }
            }
            -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=20, Direction=0, Color=#000000)";/*IE 8*/
            -moz-box-shadow: 0 0 20px rgba(0,0,0,0.3);/*FF 3.5+*/
            -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.3);/*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
            box-shadow: 0 0 20px rgba(0,0,0,0.3);/* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
            filter: progid:DXImageTransform.Microsoft.Shadow(Strength=20, Direction=135, Color=#000000); /*IE 5.5-7*/
        }
    }
    .percentage { position: relative; width:0; height:5px; background:$secondary; top:60px; @include transition(0.2s); }
    main.main { margin-top:$barHeight; height:$mainHeight;
        .col-frame { width:100%; max-width:880px; padding:5rem; }
        #logicFrame { height:$mainHeight; overflow:scroll;
            .col-frame { max-width:1130px; }
        }
        .bg-left { background:#FFFBE9; overflow-y: scroll; }
    }
    .form-check {
        &.form-check-button, &.form-check-button-outline { padding:0 !important; width:100%; height:100%; display:block;
            .form-check-input { display:none !important; }
            .form-check-label { padding:1rem; display:flex; width:100%; height:100%; justify-content: space-between; flex-direction: column;
                .title { @include font(600); }
            }
        }
        &.form-check-button { @extend .btn; @extend .btn-primary;
            &.active { @extend .btn; @extend .btn-danger; padding:0 !important; width:100%; display:block; }
        }
        &.form-check-button-outline { @extend .btn; @extend .btn-outline-primary; color:$dark !important;
            &.active { @extend .btn; @extend .btn-primary; padding:0 !important; width:100%; display:block; }
        }
    }
    .img-fluid { width:100%; height:auto; }
    .info-box { position: relative;
        .btn-help { @include font-size(1.2rem); position: relative; z-index:1; }
        .info-box-box { position: absolute; right:0; top:2.5rem; width:400px; z-index:2;
            .box-wrapper { position: relative; width:100%; height:100%; background:rgba(#fff, 0.8); margin-left:-0.9rem; border-radius: $border-radius; padding:1rem; text-align:left;
                -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=10, Direction=0, Color=#000000)";/*IE 8*/
                -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);/*FF 3.5+*/
                -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);/*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
                box-shadow: 0 5px 10px rgba(0,0,0,0.2);/* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
                filter: progid:DXImageTransform.Microsoft.Shadow(Strength=10, Direction=135, Color=#000000); /*IE 5.5-7*/
            }
        }
    }
    textarea { height:10rem; }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.root-preloader { position:fixed; width:100%; height:100%; left:0; top:0; background:$primary;
    &:before { position: absolute; width:3rem; height:3rem; line-height:3rem; text-align: center; left:50%; margin-left:-1.25rem; top:50%; margin-top:-1.25rem; font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f110"; font-size:2rem; color:$secondary; z-index:1024; animation: rotation 2s infinite linear; }
}

@media (max-width: 768px) {
    .app {
        header {
            &.header {
                .btn-back-to-website { padding-left:0.5rem;
                    span { display:none;}
                }
            }
        }
        main.main { overflow:hidden; overflow-y:scroll;
            .bg-left { overflow: visible; }
            .col-frame { padding:2rem; }
            #logicFrame { overflow: visible !important; margin-top:5rem; }
            .h-100 { height:inherit !important; height:auto !important; }
        }
    }
    .info-box {
        .info-box-box { width:300px !important; }
    }
}
